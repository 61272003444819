import { createApp } from 'vue'
import ClickOutside from '@/helpers/directives/clickOutside.js' // Adjust the import path according to your project structure
import MoveOutside from '@/helpers/directives/moveOutside.js'

import PrimeVue from 'primevue/config';
import Aura from 'primevue/themes/aura';
import vue3GoogleLogin from 'vue3-google-login'

import App from './App.vue'
import router from './router'
import store from './store/store'
import mitt from 'mitt'
import SlideUpDown from 'vue-slide-up-down'
import Tooltip from 'primevue/tooltip';

import { install } from "vue3-recaptcha-v2";

export const emitter = mitt()
import { isMobileChecker } from '@/helpers/isMobileChecker'

import './scss/global-styles.scss'
import './scss/darkTheme.scss'
import './scss/main.scss'

const app = createApp(App)

app
	.use(router)
	.use(store)
	.use(PrimeVue, {
		theme: {
			preset: Aura
		}
	})
	.use(vue3GoogleLogin, {
		clientId: '1021635873370-khrmc4qf141317b2un79j54vef16oi82.apps.googleusercontent.com'
	})
	.use(install, {
		sitekey: "6Ld1cv8pAAAAAAcfayBHp7ZCMvPiigY0B-xluBGh",
		cnDomains: false, // Optional, If you use in China, set this value true
	})
	.provide('emitter', emitter)
	.provide('isMobileCheck', isMobileChecker())
	.directive('click-outside', ClickOutside)
	.directive('move-outside', MoveOutside)
	.directive('tooltip', Tooltip)
	.component('slide-up-down', SlideUpDown)
	.mount('#app')
