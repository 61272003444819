<script setup>
import './expanded-item.scss'

import { onMounted, onUnmounted, onUpdated, computed, ref, inject, watch, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { api } from '@/arcella-api/api.js'
const store = useStore()
const props = defineProps({
	scrollingAmount: {
		type: Number,
		required: true,
		default: 0
	},
	tableWidth: {
		type: Number,
		required: true,
		default: 0
	},
	data: {
		type: Object,
		required: true
	},
	pmid: {
		type: String,
		required: true
	},
	isOpen: {
		type: Boolean,
		default: true,
		required: true
	}
})
const activedColumnsCount = computed(() => {
	let countActive = 1
	store.getters['search/allColumns'].map((item) => {
		if (item.active === true) {
			countActive++
		}
	})
	return countActive
})
function computedImageFromApi(imageUrl){
	return `https://arcella.bio/additional?key=${userApiKey.value}&image=${imageUrl}`
}
const expandImages = ref([])
const abstract = ref('')
async function getAbstractFromAPI() {
	api.expanded.getAbstract(props.pmid).then((data) => {
		abstract.value = data.data
	})
}
const publicationTypes = ref(' ')
async function getPublicationTypesFromAPI() {
	api.expanded.getPublicationtypes(props.pmid).then((data) => {
		publicationTypes.value = data.data
	})
}
const publicationFigures = ref('')
async function getPublicationFiguresFromAPI() {
	api.expanded.getArticleFigures(props.pmid).then((data) => {
		const picturesData = data.data
		if(picturesData !== null){
			console.log(picturesData)
			if(picturesData.data.length > 0 ){
				publicationFigures.value = picturesData.data
			}
		}

	})
}
const journal = ref('')
async function getJournalFromAPI() {
	api.expanded.getJournal(props.pmid).then((data) => {
		journal.value = data.data
	})
}
const emit = defineEmits(['close'])
const pmLink = computed(() => {
	return 'https://pubmed.ncbi.nlm.nih.gov/' + props.pmid
})
const pmcLink = computed(() => {
	return 'http://www.ncbi.nlm.nih.gov/pmc/articles/' + props.data.data.pmcid + '/'
})
function handleLoaded() {

}
function normalizeAuthors(string) {
	//добавляем пробел после каждого автора, и убираем последнюю запятую
	string = string.replace(/,/g, ', ').replace(/,\s*$/, '')
	return string
}
function handleClose() {
	emit('close')
}
function short(string, maxChars, isAuthors) {
	//console.log(string)
	if (string.length > maxChars) {
		string = string.substring(0, maxChars) + '...'
		string = string.replace(/,\s*$/, '')
	}
	return string
}
function handlePicLoaded(elem) {
	publicationFigures.value[elem.currentTarget.dataset.id].loaded = true
	expandImages.value.push({
		id: elem.currentTarget.dataset.id,
		src: elem.currentTarget.src,
		caption: elem.currentTarget.alt,
		width: elem.currentTarget.naturalWidth,
		height: elem.currentTarget.naturalHeight
	})
}

function handleClickPicture(index) {
	expandImages.value.sort(function (a, b) {
		return a.id - b.id
	})
	store.commit('search/updateGalleryImages', expandImages.value)
	store.commit('search/openGallery', index)
}
const tableWrapperElem = ref()
const expandedHolderElem = ref()

onUpdated((gav) => {
	//console.log(props.scrollingAmount)
})
onMounted(() => {
	getAbstractFromAPI()
	getPublicationTypesFromAPI()
	getPublicationFiguresFromAPI()
	getJournalFromAPI()
})
onUnmounted(() => {})
</script>

<template>
	<tr class="exp-row">
		<th :colspan="activedColumnsCount">
			<div
				class="exp-row-holder"
				:style="[{ transform: 'translateX(' + props.scrollingAmount + 'px)' }, { width: props.tableWidth - 55 + 'px' }]"
				ref="expandedHolderElem"
			>
				<a :href="pmLink" target="_blank" class="exp-title">{{ props.data.data.Title }}</a>
				<dl>
					<dt>PMID:</dt>
					<dd>{{ props.pmid }}</dd>
					<dt>DOI:</dt>
					<dd>
						<a :href="'https://doi.org/'+ props.data.doi" target="_blank">{{props.data.doi}}</a>
					</dd>
					<dt>Year:</dt>
					<dd>{{ props.data.data.Year }}</dd>
					<dt>Journal:</dt>
					<template v-if="journal">
						<dd>{{ journal }}</dd>
					</template>
					<template v-else>
						<div class="_g-lazy-block journal-lazy"></div>
					</template>

					<dt>Authors:</dt>
					<dd>{{ normalizeAuthors(props.data.data.Authors) }}</dd>
				</dl>
				<div class="item-block">
					<span class="block-title">Abstract</span>
					<div class="abstract-wrap" v-if="abstract">
						<p>{{ abstract.contents }}</p>
					</div>
					<div class="lazy-block-text" v-else>
						<p class="_g-lazy-block" v-for="(item, i) of 8"></p>
					</div>
				</div>
				<div class="item-block">
					<span class="block-title">Publication types</span>
					<p>
						<template v-if="publicationTypes" v-for="(pubItem, i) of publicationTypes" :key="i">
							<b>{{ pubItem }}</b
							>,
						</template>
						<template v-else>
							<div class="_g-lazy-block pub-lazy"></div>
						</template>
					</p>
				</div>
				<div class="item-block block-figures" v-if="props.isOpen === true">
					<span class="block-title">Figure</span>
					<div class="fig-list">
						<template v-if="publicationFigures">
							<div class="fig-item" v-for="(figureItem, i) of publicationFigures" :key="i">
								<div class="fig-pic" :class="{ loaded: figureItem.loaded === true }">
									<img @load="handlePicLoaded" :data-id="i" :src="computedImageFromApi(figureItem.graphic)" :alt="figureItem.caption" />
									<div class="_g-lazy-block"></div>
									<button class="open-gallery-btn" @click="handleClickPicture(i)">
										<img src="@/assets/images/vector/zoom-icon.svg" alt="" />
									</button>
								</div>
								<span class="fig-text"
									><b>{{ figureItem.label }}</b></span
								>
							</div>
						</template>
						<template v-else>
							<div class="fig-item" v-for="(item, i) of publicationFigures.length" :key="i">
								<div class="fig-pic">
									<div class="_g-lazy-block"></div>
								</div>
								<span class="fig-text _g-lazy-block"></span>
							</div>
						</template>
					</div>
				</div>
			</div>
			<div class="hide-expanded-wrapper">
				<button class="more-btn active" type="button" @click="handleClose"><span>Hide info</span><i></i></button>
			</div>
		</th>
	</tr>
</template>
