<script setup>
import './user-navigation.scss'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import router from '@/router.js'

const store = useStore()

const userMenuOpened = computed(() => {
	return store.getters['interfaceSettings/userMenuOpened']
})
const user = computed(() => {
	return store.getters['interfaceSettings/userData']
})
function handleUserSettingsDropboxMouseLeave() {
	store.commit('interfaceSettings/userMenuOpened', false)
}
function handleUserMenuBtn() {
	store.commit('interfaceSettings/userMenuOpened', !userMenuOpened.value)
}
function handleLogout(){
	store.commit('interfaceSettings/userMenuOpened', false)
	store.commit('interfaceSettings/logoutUser', false)
	store.commit('search/searchText', '')
	router.push({ name: 'FrontPage'})

}
function handleSubscriptionSettings(){
	window.open("https://billing.stripe.com/p/login/8wM1441pz4RY2acaEE");
}
onMounted(() => {})

</script>

<template>
	<div class="user-infobox jsCurrentParent" :class="{ opened: userMenuOpened }">
		<button type="button" @click="handleUserMenuBtn" class="ava">
			<img :src="user.pictureUrl" alt="" />
		</button>
		<div class="user-drop" v-click-outside="handleUserSettingsDropboxMouseLeave">
			<div class="eml-info">
				<span class="name">{{user.name}}</span>
				<span class="eml">{{user.email}}</span>
			</div>
			<div class="settings-list">
				<button type="button" @click="handleSubscriptionSettings">
					<span><b>Subscription</b></span>
					<i><img src="@/assets/images/vector/head-icon-settings.svg" alt="" /></i>
				</button>
				<button type="button" @click="store.commit('interfaceSettings/pricingWindowShowed', true)">
					<span><b>Pricing</b></span>
					<i><img src="@/assets/images/vector/payment.svg" alt="" class="pricing-icon" /></i>
				</button>
				<button type="button" @click="store.commit('interfaceSettings/isCookieAlertShowed', false)">
					<span>Privacy settings</span>
					<i><img src="@/assets/images/vector/privacy.svg" alt=""  class="privacy-icon" /></i>
				</button>
				<button type="button" @click="store.commit('interfaceSettings/guideModalOpen', true)">
					<span>User guide</span>
					<i><img src="@/assets/images/vector/header-icon-help.svg" alt="" /></i>
				</button>
				<button type="button" @click="store.commit('interfaceSettings/contactWindowShowed', true)">
					<span>Contact us</span>
					<i><img src="@/assets/images/vector/support.svg" alt="" /></i>
				</button>
				<button type="button" @click="handleLogout">
					<span>Logout</span>
					<i><img src="@/assets/images/vector/header-icon-logout.svg" alt="" /></i>
				</button>
			</div>
		</div>
	</div>
</template>
