<script setup>
import { computed, inject, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import './TextResults.scss'
import SearchTable from '@/components/search-table/search-table.vue'
import Pagination from '@/components/pagination/pagination.vue'

const store = useStore()

const searchResultColumns = computed(() => {
	return store.getters['search/resultsData']
})
const searchText = computed(() => {
	return store.getters['search/searchText']
})
const selectedIds = computed(() => {
	return store.getters['search/selectedIds']
})
const isLoading = computed(() => {
	return store.getters['search/isLoading']
})
const userFontSize = computed(() => {
	return store.getters['interfaceSettings/fontSize']
})
onMounted(() => {})

onUnmounted(() => {
})
function handleListItemClick(id) {
	if (selectedIds.value.includes(id) === false) {
		store.commit('search/addSelectedId', id)
	} else {
		store.commit('search/removeSelectedId', id)
	}
}

function addStrongToString(incomingSTR) {
	let searchWordArray = searchText.value.split(' ')
	let resultSTR
	searchText.value.split(' ').forEach((searchWord) => {
		if (incomingSTR) {
			resultSTR = incomingSTR.replace(new RegExp(`\\b(${searchWordArray.join('|')})\\b`, 'ig'), '<b>$1</b>')
		}
	})
	return resultSTR
}
</script>
<template>
	<div class="result-text-wrapper">
		<div class="sentences-list" :class="'fontSize-' + userFontSize">
			<template v-if="isLoading">
				<div class="item placeholder" v-for="(item, i) of 20">
					<div class="loader-bg"></div>
				</div>
			</template>
			<template v-else v-for="(result, i) of searchResultColumns" :key="++i">
				<div class="item" :class="{ active: selectedIds.includes(i) === true }">
					<p @click="handleListItemClick(i)" v-html="addStrongToString(result.data.Sentence)"></p>
				</div>
			</template>
		</div>
	</div>

	<search-table />
	<pagination />
</template>
