<script setup>
import './contact-window.scss'
import { onMounted, onUnmounted, computed, onUpdated, inject, watch, ref } from 'vue'
import ModalBox from '@/components/modal-box/modal-box.vue'
import { useStore } from 'vuex'
import emailjs from '@emailjs/browser';
const store = useStore()
const formElem = ref()
const canShowCapcha = ref(false)
const sendedClass = ref('')
import { RecaptchaV2 } from "vue3-recaptcha-v2";
import privacyPdf from '@/assets/pdf/PRIVACY_POLICY_ARCELLA.pdf'

const progressSend = ref(false)
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'

const { errors, handleSubmit, defineField, setFieldError, setErrors } = useForm({
	validationSchema: yup.object({
		email: yup.string().email().required(),
		name: yup.string().required(),
		rec: yup.string().required().required('You must complete capcha'),
		lastname: yup.string().required(),
		message: yup.string().required(),
		agree: yup.string().min(1, 'Please agree').required('You must agree with terms')
	})
	// initialErrors: {
	// 	email: 'This email is already taken',
	// 	agree: 'You must agree with our terms',
	// },
})
const [email, emailAttrs] = defineField('email')
const [rc, rcAttrs] = defineField('rec')
const [name, nameAttrs] = defineField('name')
const [lastname, lastnameAttrs] = defineField('lastname')
const [message, messageAttrs] = defineField('message')
const { handleChange, checked } = useField(() => 'agree', undefined, {
	type: 'checkbox',
	checkedValue: 'yes'
})
const user = computed(() => {
	return store.getters['interfaceSettings/userData']
})
onMounted(() => {
	if(user.value.email){
		email.value = user.value.email
	}
})
const onSubmit = handleSubmit((values) => {
	const formData = JSON.stringify(values)

	progressSend.value = true
	emailjs
		.sendForm('service_hdnme8a', 'template_s6je5vl', formElem.value, {
			publicKey: 'ce65b91aRTOiGbq3R',
		})
		.then(
			() => {
				console.log('SUCCESS!');
				progressSend.value = false

				// here you do what you want with response
				sendedClass.value = 'success-send'

				setTimeout(function () {
					sendedClass.value = ''
					store.commit('interfaceSettings/contactWindowShowed', false)
				}, 3000)
			},
			(error) => {
				console.log('FAILED...', error.text);
			},
		);

})
const handleWidgetId = (widgetId) => {
	//console.log("Widget ID: ", widgetId);
	setTimeout(() => {
		canShowCapcha.value = true
	}, 1000)
};
const handleErrorCalback = () => {
	console.log("Error callback");
};
const handleExpiredCallback = () => {
	console.log("Expired callback");
};
const handleLoadCallback = (response) => {
	console.log(response)
	rc.value = response
};
function handleModalClose() {
	store.commit('interfaceSettings/contactWindowShowed', false)
}
</script>

<template>
	<modal-box class-name="contact-modal" :frame-class="sendedClass" @close="handleModalClose">
		<div class="modal-heading">
			<span class="modal-window-title">Contact us</span>
		</div>
		<form class="contact-form" ref="formElem" action="/contact-send.php" @submit="onSubmit">
			<div class="form-inputs">
				<div class="form-group form-row">
					<div class="col">
						<div class="form-item-wrap" :class="{ 'has-danger': errors.name }">
							<input
								class="form-control"
								v-model="name"
								v-bind="nameAttrs"
								placeholder="Name"
								type="text"
								name="name"
								ref="emailInput"
								aria-label="Name"
							/>
							<span class="pristine-error" v-if="errors.name">{{ errors.name }}</span>
						</div>
					</div>
					<div class="col">
						<div class="form-item-wrap" :class="{ 'has-danger': errors.lastname }">
							<input
								class="form-control"
								v-model="lastname"
								v-bind="lastnameAttrs"
								placeholder="Lastname"
								name="lastname"
								type="text"
								aria-label="Lastname"
							/>
							<span class="pristine-error" v-if="errors.lastname">{{ errors.lastname }}</span>
						</div>
					</div>
				</div>
				<div class="form-group form-row">
					<div class="col">
						<div class="form-item-wrap" :class="{ 'has-danger': errors.email }">
							<input
								class="form-control"
								v-model="email"
								v-bind="emailAttrs"
								placeholder="Email address"
								name="email"
								type="text"
								aria-label="Email"
							/>
							<span class="pristine-error" v-if="errors.email">{{ errors.email }}</span>
						</div>
					</div>
					<div class="col">
						<div class="form-item-wrap">
							<input class="form-control" placeholder="Organization" name="org" type="text" aria-label="Organization" />
						</div>
					</div>
				</div>
				<div class="form-group">
					<div class="form-item-wrap">
						<label class="select" for="selectChoice">
							<select aria-label="Choice question" id="selectChoice" name="question_type">
								<option selected disabled value="Not choiced">Choose your question</option>
								<option value="General inquiries">General inquiries</option>
								<option value="Enterprise inquiries">Enterprise inquiries</option>
								<option value="Technical questions">Technical questions</option>
								<option value="Payment questions">Payment questions</option>
								<option value="Feedback">Feedback</option>
							</select>
							<i><img src="@/assets/images/vector/short-arrow.svg" alt="" /></i>
						</label>
					</div>
				</div>
				<div class="form-group">
					<div class="form-item-wrap" :class="{ 'has-danger': errors.message }">
						<textarea
							class="form-control"
							v-model="message"
							v-bind="messageAttrs"
							cols="1"
							rows="3"
							placeholder="Type your message"
							name="message"
							aria-label="message"
						></textarea>
						<span class="pristine-error" v-if="errors.message">{{ errors.message }}</span>
					</div>
				</div>
				<div class="form-group form-row policy-box">

					<div class="col">
						<div class="form-item-wrap" :class="{ 'has-danger': errors.agree }">
							<label class="custom-checkbox">
								<input type="checkbox" :checked="checked" @change="handleChange" name="agree" value="yes" />
								<i class="ch-btn"></i>
								<span class="checkbox-holder">
									<span>I consent to having this website store my submitted information so they can respond to my inquiry. See
								our <a :href="privacyPdf" target="_blank">privacy policy</a> to learn more
								about how we use data.</span></span
								>
							</label>
							<span class="pristine-error" v-if="errors.agree">{{ errors.agree }}</span>
						</div>
					</div>
					<div class="col">
						<div class="form-item-wrap" :class="{ 'has-danger': errors.message }">
							<input type="hidden"  v-model="rc" v-bind="rcAttrs" name="rec">
							<span class="pristine-error" v-if="errors.rec">{{ errors.rec }}</span>
							<p class="_g-lazy-block lazy-capcha"><em>Loading capcha...</em></p>
							<div class="capcha-wrapper" :class="{ 'show': canShowCapcha === true }">
								<RecaptchaV2
									@widget-id="handleWidgetId"
									@error-callback="handleErrorCalback"
									@expired-callback="handleExpiredCallback"
									@load-callback="handleLoadCallback"
								/>
							</div>

						</div>
					</div>

				</div>

				<div class="submit-wrap">
					<button class="submit-btn" type="submit" :disabled="progressSend === true">
						<span>send a message</span>
						<i><img src="@/assets/images/vector/short-arrow.svg" alt="" /></i>
						<em><img src="@/assets/images/vector/curve-spinner.svg" alt=""></em>
					</button>
				</div>
			</div>
		</form>
		<div class="success-abs-box">
			<div class="centered">
				<div class="icon-box-wrap">
					<div class="success-icon">
						<picture>
							<source
								srcset="@/assets/images/sign-modal-success.webp, @/assets/images/sign-modal-success2x.webp 2x"
								type="image/webp"
							/>
							<img class="img-responsive" src="@/assets/images/sign-modal-success.webp" alt="" />
						</picture>
						<div class="galka"></div>
					</div>
				</div>
				<div class="success-bottom"><span class="success-subtitle">Your message was sent!</span></div>
			</div>
		</div>
		<div class="close-progress">
			<div class="progress-fill"></div>
		</div>
	</modal-box>
</template>
