<script setup>
import './guide-window.scss'
import { onMounted, onUnmounted, computed, onUpdated, inject, watch, ref } from 'vue'

import ModalBox from '@/components/modal-box/modal-box.vue'
import { useStore } from 'vuex'
const store = useStore()
const videoGalleryElem = ref()
const videoElem = ref()
const videoUrl = ref('')
const isOpenVideo = ref(false)
const isVideoLoaded = ref(false)
import video1 from '@/assets/video/arcella_text_search_guide.mp4'
import video2 from '@/assets/video/arcella_figure_search_guide.mp4'
import video3 from '@/assets/video/arcella_image_similarity_search.mp4'

onMounted(() => {


})

function handleModalClose() {
	store.commit('interfaceSettings/guideModalOpen', false)
}

function handleOpenGallery(url){
	videoUrl.value = url
	isOpenVideo.value = true
}
function handleVideoClose(){
	isOpenVideo.value = false
	isVideoLoaded.value = false
}
function handleVidPlay(){
	isVideoLoaded.value = true
	videoElem.value.play()
}
</script>

<template>
	<modal-box class-name="guide-modal" @close="handleModalClose">

		<template v-slot:fixbox >
			<Transition name="fade" >
				<div class="video-gallery-full" v-if="isOpenVideo === true" :class="{ 'loaded': isVideoLoaded === true }">
					<button class="close-btn" @click="handleVideoClose"><span></span></button>
					<button class="bg-close-video" type="button" @click="handleVideoClose"></button>
					<div class="loader-pic">
						<img src="@/assets/images/vector/curve-spinner.svg" alt="">
					</div>
					<div class="full-item" >
						<video controls playsinline tabindex="0" ref="videoElem" @canplaythrough="handleVidPlay">
							<source :src="videoUrl" type="video/mp4">
						</video>
					</div>
				</div>
			</Transition>

		</template>
		<div class="modal-heading">
			<span class="modal-window-title">Arcella User Guide</span>
		</div>
		<div class="guide-list" id="videoGallery" ref="videoGalleryElem">
			<div class="guide-item">

				<button type="button" @click="handleOpenGallery(video1)" class="abs-link" ></button>
				<div class="image-box">
					<div class="play-icon"><img src="@/assets/images/vector/play-button.svg" alt=""></div>
					<img src="@/assets/images/vector/arcella-logo-dark-circle.svg" alt="" class="image">
				</div>
				<div class="desc-box">
					<span class="name">Sentence search</span>
				</div>
			</div>
			<div class="guide-item">
				<button type="button" @click="handleOpenGallery(video2)" class="abs-link" ></button>

				<div class="image-box">
					<div class="play-icon"><img src="@/assets/images/vector/play-button.svg" alt=""></div>
					<img src="@/assets/images/vector/arcella-logo-dark-circle.svg" alt="" class="image">
				</div>
				<div class="desc-box">
					<span class="name">Figure search</span>
				</div>
			</div>
			<div class="guide-item">
				<button type="button" @click="handleOpenGallery(video3)" class="abs-link" ></button>
				<div class="image-box">
					<div class="play-icon"><img src="@/assets/images/vector/play-button.svg" alt=""></div>
					<img src="@/assets/images/vector/arcella-logo-dark-circle.svg" alt="" class="image">
				</div>
				<div class="desc-box">
					<span class="name">Image Similarity search</span>
				</div>
			</div>
		</div>
	</modal-box>
</template>
