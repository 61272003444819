<script setup>
import './login-window.scss'
import { computed, onMounted, ref } from 'vue'
import ModalBox from '@/components/modal-box/modal-box.vue'
import { useStore } from 'vuex'
import { googleTokenLogin } from 'vue3-google-login'
import { api } from '@/arcella-api/api.js'
import defaultAva from '@/assets/images/user-ava.png'

const store = useStore()
const isMustLogin = computed(() => store.getters['interfaceSettings/isMustLogin'])
import InputOtp from 'primevue/inputotp';
const userLoginInProgress = computed(() => {
	return store.getters['interfaceSettings/userLoginInProgress']
})
import termsPdf from '@/assets/pdf/TERMS_OF_SERVICE_ARCELLA(2024).pdf'
import cookiePdf from '@/assets/pdf/COOKIE_POLICY_ARCELLA(2024).pdf'
import privacyPdf from '@/assets/pdf/PRIVACY_POLICY_ARCELLA.pdf'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
const { errors, handleSubmit, defineField, setFieldError, setErrors } = useForm({
	validationSchema: yup.object({
		email: yup.string().email().required(),
		password: yup.string().required().min(6),
	}),
	validateOnMount:false
	// initialErrors: {
	// 	email: 'This email is already taken',
	// 	agree: 'You must agree with our terms',
	// },
})
const [email, emailAttrs] = defineField('email')
const [password, passwordAttrs] = defineField('password')
const loginFormElem = ref()
const codeConfirmMode = ref(false)
const confirmCode = ref()
const loginCodeFormElem = ref()
const codeResendTimer = ref(30)
const codeResendTimerText = ref('30')
const isCodeInvalid = ref(false)
let resendConfirmCodeInterval
const login = () => {
	googleTokenLogin().then((response) => {
		store.commit('interfaceSettings/userLoginInProgress', true)
		const recievedUserInfo = async (token) => {
			const googleAccountResponse = await api.userservices.googleAccInfo(response.access_token)
			const arcellaResponse = await api.userservices.googleSignUp(response.access_token)
			console.log(arcellaResponse.data.apikey)
			return {
				apiKey: arcellaResponse.data.apikey,
				email: arcellaResponse.data.email,
				emailVerified: arcellaResponse.data.emailVerified,
				userId: arcellaResponse.data.userId,
				pictureUrl: googleAccountResponse.data.picture,
				familyName: googleAccountResponse.data.family_name,
				givenName: googleAccountResponse.data.given_name,
				name: googleAccountResponse.data.name
			}
		}
		recievedUserInfo(response.access_token)
			.then((userData) => {
				store.commit('interfaceSettings/setUser', userData)
				store.commit('interfaceSettings/userLoginInProgress', false)
				store.commit('interfaceSettings/loginModalOpen', false)
				store.commit('interfaceSettings/isMustLogin', false)

			})
			.catch((e) => {
				console.log('There has been problem: ' + e.message)
			})



	})
}
function addZero(num){
	return ('0' + num).slice(-2)

}
function runCountdownInterval(){
	codeResendTimer.value = 30
	codeResendTimerText.value = '30'
	resendConfirmCodeInterval = setInterval(() => {
		codeResendTimer.value--
		codeResendTimerText.value = addZero(codeResendTimer.value)
		if(codeResendTimer.value <= 0){
			clearInterval(resendConfirmCodeInterval)
		}

	}, 1000)
}
const onSubmitLogin = handleSubmit((values) => {
	store.commit('interfaceSettings/userLoginInProgress', true)
	api.userservices.emailLogin(email.value,password.value).then((response) => {
		store.commit('interfaceSettings/userLoginInProgress', false)
		if (response.data === 'code sent') {
			codeConfirmMode.value = true
			setTimeout(() => {
				console.log(loginCodeFormElem.value.querySelector('input'))
				loginCodeFormElem.value.querySelector('input').focus()
			}, 100)
			runCountdownInterval()
		}
		else{
			successEmailLogin(response.data)
		}

	})
})

onMounted(() => {

})

function handleModalClose() {
	store.commit('interfaceSettings/loginModalOpen', false)
	if(isMustLogin.value === false){

	}
}

function handleResendConfirmationCode() {
	runCountdownInterval()
}
function handleCompleteOtpInput() {
	console.log(`otttpp`)

	if(confirmCode.value.length === 6){
		console.log(`send topt!!`)
		sendEmailConfirmationCode()
	}
}
function handleStartOver(){
	clearInterval(resendConfirmCodeInterval)
	isCodeInvalid.value = false
	codeConfirmMode.value = false
	email.value = null
	password.value = null
	confirmCode.value = null
	store.commit('interfaceSettings/userLoginInProgress', false)
}
function successEmailLogin(apiKey){
	store.commit('interfaceSettings/setUser', {
		emailVerified: true,
		apiKey: apiKey,
		pictureUrl: defaultAva,
		familyName: 'User',
		givenName: 'user',
		name: 'User',
		userId: '',
		email: email.value
	})
	store.commit('interfaceSettings/userLoginInProgress', false)
	store.commit('interfaceSettings/loginModalOpen', false)
	store.commit('interfaceSettings/isMustLogin', false)
}
function sendEmailConfirmationCode(){
	store.commit('interfaceSettings/userLoginInProgress', true)
	api.userservices.emailLoginConfirmCode(email.value,confirmCode.value).then((response) => {
		console.log(response.data)
		if(response.data.length > 10){
			clearInterval(resendConfirmCodeInterval)
			successEmailLogin(response.data)
		}
		else{
			isCodeInvalid.value = true
			store.commit('interfaceSettings/userLoginInProgress', false)
			// confirmCode.value = ''
			// loginCodeFormElem.value.querySelector('input').focus()
		}

	})
}
</script>

<template>
	<modal-box class-name="login-modal" @close="handleModalClose" >
		<div class="loader-pic" :class="{ loading: userLoginInProgress === true}">
			<img src="@/assets/images/vector/curve-spinner.svg" alt="">
		</div>
		<div class="login-heading">
			<span class="login-window-title">Login to Arcella</span>
		</div>
		<div class="login-buttons">
			<div class="login-mode-box" v-if="codeConfirmMode === false" >
				<div class="item">
					<button class="login-btn" type="button" @click="login">
						<i><img src="@/assets/images/vector/google-btn.svg" alt="" /></i>
						<span>Continue with Google</span>
					</button>
				</div>
				<div class="item" v-if="false">
					<button class="login-btn btn-apple" type="button">
						<i><img src="@/assets/images/vector/apple-btn.svg" alt="" /></i>
						<span>Continue with Apple</span>
					</button>
				</div>
				<div class="or"><span>or</span></div>
				<form class="login-form" ref="loginFormElem" @submit="onSubmitLogin">
					<div class="form-group">
						<div class="form-item-wrap" :class="{ 'has-danger': errors.email }">
							<input
								class="form-control"
								v-model="email"
								v-bind="emailAttrs"
								placeholder="Email"
								type="email"
								name="email"
								ref="emailInput"
								aria-label="Email"
								data-vv-validate-on="custom"
							/>
							<span class="pristine-error" v-if="errors.email">{{ errors.email }}</span>
						</div>
					</div>
					<div class="form-group">
						<div class="form-item-wrap" :class="{ 'has-danger': errors.password }">
							<input
								class="form-control"
								v-model="password"
								v-bind="passwordAttrs"
								placeholder="Password"
								type="password"
								name="password"
								ref="passwordInput"
								aria-label="Password"
							/>
							<span class="pristine-error" v-if="errors.password">{{ errors.password }}</span>
						</div>
					</div>
					<div class="submit-wrap">
						<button class="submit-btn" type="submit" :disabled="userLoginInProgress === true">
							<span>Login</span>
							<i><img src="@/assets/images/vector/short-arrow.svg" alt="" /></i>
						</button>

					</div>
				</form>
			</div>

			<div v-else class="login-mode-box">
				<form  class="login-form" ref="loginCodeFormElem">
					<div class="sended-infobox">
						<div class="icon"><img src="@/assets/images/vector/email-envelope.svg" alt=""></div>
						<p>Enter verification code we sent to</p>
						<span class="emailentered">{{email}}</span>
					</div>
					<div class="form-group confirm-code-box">
						<div class="form-item-wrap">
							<InputOtp :length="6" v-model="confirmCode" :invalid="isCodeInvalid === true" ref="inpOtp" @change="handleCompleteOtpInput" />
						</div>
					</div>
					<div class="resend-box">
						<button v-if="codeResendTimer <= 0" class="resend-btn" type="button" @click="handleResendConfirmationCode"><span>Resend code</span></button>
						<span v-else class="resend-time">Resend code in <b>00:{{ codeResendTimerText }}</b></span>
					</div>
					<div class="again-box">
						<p>Didn't recieve a code? Check your spam folder, <br>or <button type="button" @click="handleStartOver">try a different email address</button></p>
					</div>
				</form>
			</div>

		</div>
		<div class="warn-box">
			<p>
				By Signing up, you agree to the
				<a target="_blank" :href="termsPdf">Terms of Service</a> and
				<a target="_blank" :href="privacyPdf">Privacy Policy</a>, including
				<a target="_blank" :href="cookiePdf">Cookies</a>
			</p>
		</div>
	</modal-box>
</template>
