import { api } from '@/arcella-api/api.js'

export default {
	namespaced: true,
	state() {
		return {
			darkTheme: false,
			hiddenSidebar: false,
			isFigureSearch: false,
			fontSize: 2,
			tableWidth: null,
			isLoginModalOpen: false,
			isCookieAlertShowed: false,
			necessaryCookies: false,
			analyticalCookies: false,
			pricingWindowShowed: false,
			contactWindowShowed: false,
			userMenuOpened: false,
			isUserLogged: false,
			userLoginInProgress: false,
			isMustLogin: false,
			isGuideModalOpen: false,
			isMaintenance: false,
			user: {
				emailVerified: true,
				apiKey: 'TRIAL',
				pictureUrl: '',
				familyName: '',
				givenName: '',
				name: '',
				userId: '',
				email: ''
			}
		}
	},

	actions: {
		loadAllPictures({ commit }, status) {
			commit('makeLoadAllPictures', true)
		},
		actReadyToLoad({ commit }, value) {
			commit('readyToLoad', value)
		},
		resetPreloader({ commit }) {
			commit('flushLoader')
		}
	},

	mutations: {
		initialiseStore(state,commit) {
			// Check if the ID exists
			if (localStorage.getItem('interfaceSettings')) {
				// Replace the state object with the stored item
				const localStorageObject = JSON.parse(localStorage.getItem('interfaceSettings'))
				state.darkTheme = localStorageObject.darkTheme
				state.hiddenSidebar = localStorageObject.hiddenSidebar
				state.fontSize = localStorageObject.fontSize
				state.isCookieAlertShowed = localStorageObject.isCookieAlertShowed
				state.necessaryCookies = localStorageObject.necessaryCookies
				state.analyticalCookies = localStorageObject.analyticalCookies
				state.isUserLogged = localStorageObject.isUserLogged
				state.user = localStorageObject.user
				state.isMustLogin = localStorageObject.isMustLogin
				state.isMaintenance = localStorageObject.isMaintenance
				// this.replaceState(
				// 	Object.assign(state, JSON.parse(localStorage.getItem('interfaceSettings')))
				// );
				api.userservices.isMaintenanceCheck()
					.then((data) => {
						this.commit('interfaceSettings/isMaintenance', data.data)
					})
			}
		},
		fontSizePlus(state, url) {
			if (state.fontSize <= 2) state.fontSize++
		},
		fontSizeMinus(state, url) {
			if (state.fontSize >= 1) state.fontSize--
		},
		resetSettings(state, url) {
			state.fontSize = 2
			state.hiddenSidebar = false
			state.darkTheme = false
		},
		hideSidebar(state, value) {
			state.hiddenSidebar = value
		},
		darkTheme(state, value) {
			state.darkTheme = value
		},
		setTableWidth(state, value) {
			state.tableWidth = value
		},
		isCookieAlertShowed(state, value) {
			state.isCookieAlertShowed = value
		},
		loginModalOpen(state, value) {
			state.isLoginModalOpen = value
		},
		guideModalOpen(state, value) {
			state.isGuideModalOpen = value
		},
		necesaryCookies(state, value) {
			state.necessaryCookies = value
		},
		analyticalCookies(state, value) {
			state.analyticalCookies = value
		},
		setUser(state, value) {
			state.isUserLogged = true
			state.user = value
		},
		logoutUser(state, value) {
			state.isUserLogged = false
			state.user = {
				emailVerified: false,
				apiKey: 'TRIAL',
				pictureUrl: '',
				familyName: 'Guest',
				givenName: 'guest',
				name: 'Guest name',
				userId: '',
				email: ''
			}
		},
		userLoginInProgress(state, value) {
			state.userLoginInProgress = value
		},
		pricingWindowShowed(state, value) {
			state.pricingWindowShowed = value
		},
		contactWindowShowed(state, value) {
			state.contactWindowShowed = value
		},
		userMenuOpened(state, value) {
			state.userMenuOpened = value
		},
		isMustLogin(state, value) {
			state.isMustLogin = value
		},
		isMaintenance(state, value) {
			state.isMaintenance = value
		},
	},

	getters: {
		fontSize(state) {
			return state.fontSize
		},
		hiddenSidebar(state) {
			return state.hiddenSidebar
		},
		darkTheme(state) {
			return state.darkTheme
		},
		tableWidth(state) {
			return state.tableWidth
		},
		isLoginModalOpen(state) {
			return state.isLoginModalOpen
		},
		isCookieAlertShowed(state) {
			return state.isCookieAlertShowed
		},
		necesaryCookies(state) {
			return state.necessaryCookies
		},
		analyticalCookies(state) {
			return state.analyticalCookies
		},
		isUserLogged(state) {
			return state.isUserLogged
		},
		isGuideModalOpen(state) {
			return state.isGuideModalOpen
		},
		userData(state) {
			return state.user
		},
		userLoginInProgress(state) {
			return state.userLoginInProgress
		},
		pricingWindowShowed(state) {
			return state.pricingWindowShowed
		},
		contactWindowShowed(state) {
			return state.contactWindowShowed
		},
		isMustLogin(state) {
			return state.isMustLogin
		},
		userMenuOpened(state) {
			return state.userMenuOpened
		},
		isMaintenance(state) {
			return state.isMaintenance
		}
	}
}
