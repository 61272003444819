<script setup>
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin';

import 'photoswipe/style.css';
import 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css';
import './photoswipe-gallery.scss'
import { onMounted, onUnmounted, computed, ref, onUpdated, inject, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const props = defineProps({
	openIndex: {
		type: Number,
		required: true,
		default: 0
	},
})

const figuresGallery = computed(() => {
	return store.getters['search/figuresGallery']
})


const lightbox = new PhotoSwipeLightbox({
	gallery: '#my-gallery',
	dataSource: figuresGallery.value,
	showHideAnimationType: 'fade',
	bgOpacity: 0.8,
	pswpModule: () => import('photoswipe'),
	// Optional padding for images,
	// note that this is an option of PhotoSwipe, not a plugin
	paddingFn: (viewportSize) => {
		return {
			top: 30, bottom: 30, left: 70, right: 70
		}
	},
});

const captionPlugin = new PhotoSwipeDynamicCaption(lightbox, {
	// Plugins options, for example:
	type: 'auto',
	captionContent: (slide) => {
		return slide.data.caption;
	}
});






onMounted(() => {
	lightbox.loadAndOpen(props.openIndex);


	// setTimeout(() => {
	// 	const elements = document.getElementsByClassName('pswp__dynamic-caption--aside')
	// 	console.log(elements)
	// 	for (let item of elements) {
	// 		console.table(`bla`)
	// 		console.log(item);
	// 		item[0].setAttribute("ncontenteditableame", "true");
	//
	//
	// 	}
	// }, 300)





})



lightbox.on('pointerDown', (e) => {

	if (e.originalEvent.target.classList.contains('pswp__dynamic-caption')){
		e.preventDefault();
	}

});
lightbox.addFilter('preventPointerEvent', (preventPointerEvent, originalEvent, pointerType) => {
	// return true to preventDefault pointermove/pointerdown events
	// (also applies to touchmove/mousemove)
	return true;
});
lightbox.on('close', () => {
	store.commit('search/openGallery', null)
	// photoswipe starts to open
});
onUnmounted(() => {
	lightbox.destroy()
})





</script>

<template>

</template>
