<script setup>
import './cookie-alert.scss'
import { onMounted, onUnmounted, computed, onUpdated, inject, ref, watch } from 'vue'
import { useStore } from 'vuex'
const store = useStore()
const emit = defineEmits(['close'])
import cookiePdf from '@/assets/pdf/COOKIE_POLICY_ARCELLA(2024).pdf'

onMounted(() => {})
const acceptNecessaryCookies = ref(true)
const acceptAnalyticalCookies = ref(true)
function handleModalClose() {
	//store.commit('interfaceSettings/isCookieAlertShowed', true)
	emit('close')
}
function handleCookieCheckboxChange(ev) {
	console.log(acceptNecessaryCookies.value)
}
function handleAcceptNecessary(ev) {
	acceptAnalyticalCookies.value = false
	store.commit('interfaceSettings/necesaryCookies', acceptNecessaryCookies.value)
	store.commit('interfaceSettings/analyticalCookies', acceptAnalyticalCookies.value)
	store.commit('interfaceSettings/isCookieAlertShowed', true)
	emit('close')
}
function handleAcceptAll(ev) {
	acceptNecessaryCookies.value = true
	acceptAnalyticalCookies.value = true
	store.commit('interfaceSettings/necesaryCookies', acceptNecessaryCookies.value)
	store.commit('interfaceSettings/analyticalCookies', acceptAnalyticalCookies.value)
	store.commit('interfaceSettings/isCookieAlertShowed', true)
	emit('close')
}
</script>

<template>
	<div class="cookie-alert-box">
		<button class="close-btn"  type="button" @click="handleModalClose()"><span></span></button>
		<div class="box-holder">
			<div class="title-wrap">
				<span class="box-title">Our website uses cookies</span>
			</div>
			<div class="top-content">

				<div class="left-col">
					<p>Arcella search would like to use our own and third party cookies and similar technologies for: <span>necessary purposes</span> and <span>analytical purposes</span></p>
					<p>You can set your preferences by selecting the options below. Withdraw your consent at any time via the shield icon. </p>
					<p><a :href="cookiePdf" target="_blank">Learn more about how we use cookies and who sets cookies on our website</a></p>
				</div>
				<div class="right-col">
					<div class="checks">
					<div class="check-item">
						<label class="custom-checkbox">
							<input
								type="checkbox"
								checked
								disabled
								name="cookiesCheckboxes"
								value="necessary"
							/>
							<i class="ch-btn"></i>
							<span class="checkbox-holder"><b>Necessary cookies</b> - help with the basic functionality of our website, e.g. remember if you gave consent to cookies, font-size, dark or light theme, etc.</span>
						</label>
					</div>
					<div class="check-item">
						<label class="custom-checkbox">
							<input
								type="checkbox"
								v-model="acceptAnalyticalCookies"
								@change="handleCookieCheckboxChange($event,i)"
								name="cookiesCheckboxes"
								value="analytical"
							/>
							<i class="ch-btn"></i>
							<span class="checkbox-holder"><b>Analytical cookies</b> - make it possible to gather statistics about the use and traffic on our website, so we can make it better.</span>
						</label>
					</div>
				</div>
				</div>
			</div>

			<div class="bottom-content">

				<div class="buttons-row">
					<button class="accept-btn" type="button" @click="handleAcceptNecessary()">Accept necessary</button>
					<button class="accept-btn highlighted" type="button" @click="handleAcceptAll()">Accept all</button>
				</div>
			</div>

		</div>
	</div>
</template>
