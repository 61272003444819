<script setup>
import './pricing-window.scss'
import { onMounted, defineComponent,  onBeforeMount, onUnmounted, computed, onUpdated, inject, watch, ref } from 'vue'
import ModalBox from '@/components/modal-box/modal-box.vue'
import { useStore } from 'vuex'
import { api } from '@/arcella-api/api.js'
const store = useStore()
const redirWindowOpen = ref(false)
let userSessionKey = ref()
let stripeError = ref()
let userKeyLoaded = ref(false)
const monthStripePriceId = 'price_1PMDqZEyawn8ySvc55e7J0dO'
const yearStripePriceId = 'price_1PY3K7Eyawn8ySvcVQKYVJ6Y'
// const monthStripePriceId = 'price_1PXrmDEyawn8ySvcAM9WcOPR'
// const yearStripePriceId = 'price_1PXqwsEyawn8ySvcGpWpfDdY'
const stripeMonthUrl = ref()
const stripeYearUrl = ref()
onMounted(() => {
	api.userservices.stripeSessionSecret().then((response) => {
		userSessionKey.value = response.data
		console.log(userSessionKey.value)
		userKeyLoaded.value = true
	})

})
function handleModalClose() {
	store.commit('interfaceSettings/pricingWindowShowed', false)
}
function handlePayBtn(priceID) {
	redirWindowOpen.value = true
	console.log(priceID)
	api.userservices.stripeSessionGenerateLink(priceID).then((response) => {
		if(response.data.length > 0){
			//check when payment is done!
			window.location.href = response.data;
		}
		else{
			stripeError.value = response.data
			console.log(`something is wrong: response is:`)
			console.log(response.data)
		}
	})
}
function handleContactBtn() {
	store.commit('interfaceSettings/pricingWindowShowed', false)
	store.commit('interfaceSettings/contactWindowShowed', true)
}
</script>
<template>
	<modal-box class-name="pricing-modal" @close="handleModalClose">
		<div class="modal-heading">
			<span class="modal-window-title">Arcella Search Pricing</span>
		</div>

		<div class="redir-window-box"  :class="{showed: redirWindowOpen === true}" >
			<i><img src="@/assets/images/vector/loading-columns.svg" alt="" /></i>
			<span class="redir-text"><span>Redirecting to <em>Stripe</em>...</span> </span>
			<span class="errow-box" v-if="stripeError">{{stripeError}}</span>
		</div>
		<div class="pricing-table">
			<div class="pricing-item">
				<div class="item-holder">
					<div class="item-frame">
						<div class="item-heading-box">
							<span class="plan-name">1-month plan</span>
 						</div>
						<div class="price-amount-box">
							<span class="limited-price">Introductory price</span>
							<div class="price"><b>€6.99</b><span>/month</span></div>
						</div>
						<div class="get-wrapper">
							<button @click="handlePayBtn(monthStripePriceId)" class="get-btn" type="button"><span>Get 1-month plan</span></button>
						</div>
						<span class="billed">Billed at €6.99 every month</span>
					</div>
				</div>
			</div>
			<div class="pricing-item featured">
				<div class="item-holder">
					<div class="featured-label">BEST DEAL</div>
					<div class="item-frame">
						<div class="item-heading-box">
							<span class="plan-name">1-year plan</span>
						</div>
						<div class="price-amount-box">
							<span class="limited-price">Introductory price</span>
							<div class="price"><b>€4.99</b><span>/month</span></div>
						</div>
						<div class="get-wrapper">
							<button @click="handlePayBtn(yearStripePriceId)" class="get-btn" type="button"><span>Get 1-year plan</span></button>
						</div>
						<span class="billed">Billed at  €59.99 for 1 year</span>
						<span class="add-sale-text">Get €10 off with code ARC10</span>
					</div>
				</div>
			</div>
			<div class="pricing-item enterprice">
				<div class="item-holder">
					<div class="item-frame">
						<div class="item-heading-box">
							<span class="enterprice-title">Enterprise</span>
						</div>
						<div class="price-amount-box">
							<div class="price">Please contact us <br>for a quote</div>

						</div>
						<div class="get-wrapper">
							<button class="get-btn" @click="handleContactBtn" type="button"><span>Contact us</span></button>
						</div>
						<span class="billed">For Vendors, BioPharma and Academia</span>
					</div>

				</div>
			</div>
		</div>
		<div class="pricing-info-bottom-row">
			<div class="what-get-box">
				<span class="get-title"><b>Subscription includes:</b></span>
				<ul class="abilites-list">
					<li>Keyword search</li>
					<li>Unlimited export XLS, TSV, CSV</li>

					<li>Image similarity search</li>
					<li>25,000 sentences/images per month</li>
				</ul>
			</div>
			<div class="advanced-plan-box">
				<div class="adv-plan">
					<span class="badge">Coming soon</span>
					<span class="plan-name">Advanced search</span>
					<span class="what-do">Powered by 40+ ontologies and visualization tool to enhance your data exploration</span>
				</div>
			</div>
		</div>
		<div class="eco-box">
			<i><img src="@/assets/images/vector/earth-day2.svg" alt=""></i>
			<span>At Arcella we are committed to give back to Earth and will contribute 1% <br>of our worldwide turnover to organisations working to protect the environment.</span>
		</div>

	</modal-box>
</template>
