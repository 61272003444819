<script setup>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
const store = useStore()
const router = useRoute()
import { computed, onMounted, onBeforeMount, ref, watch, nextTick } from 'vue'

import FileuploadBox from '@/components/fileupload-box/fileuploadBox.vue'
import LoginWindow from '@/components/login-window/login-window.vue'
import cookieAlert from './components/cookie-alert/cookie-alert.vue'
import PricingWindow from '@/components/pricing-window/pricing-window.vue'
import ContactWindow from '@/components/contact-window/contact-window.vue'
import GuideWindow from '@/components/guide-window/guide-window.vue'

const layout = computed(() => {
	return router.meta.layout
})

const loadingText = ref('loading columns data')

const isLoadedAdditionColumns = computed(() => {
	return store.getters['search/isAllAdditionColumnsLoaded']
})
const arcGalleryOpened = computed(() => {
	return store.getters['search/arcGalleryOpen']
})
const searchByFigureModalOpen = computed(() => {
	return store.getters['search/searchByFigureModalOpen']
})
const isLoginModalOpen = computed(() => {
	return store.getters['interfaceSettings/isLoginModalOpen']
})
const isUserLogged = computed(() => {
	return store.getters['interfaceSettings/isUserLogged']
})
const trialEnded = computed(() => {
	return store.getters['search/isMustPay']
})
const isGuideModalOpen = computed(() => store.getters['interfaceSettings/isGuideModalOpen'])

const isCookieAlertShowedStore = computed(() => {
	return store.getters['interfaceSettings/isCookieAlertShowed']
})
const pricingWindowShowed = computed(() => {
	return store.getters['interfaceSettings/pricingWindowShowed']
})

const contactWindowShowed = computed(() => store.getters['interfaceSettings/contactWindowShowed'])
const showCookiesAlert = ref(false)
const body = document.body
window.addEventListener('scroll', () => {
	document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}`)
})

watch(
	() => arcGalleryOpened.value,
	(isOpen) => {
		if (isOpen != null) {
			document.body.classList.add('arc-gallery-opened')
			let scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
			document.documentElement.style.setProperty('--scroll-y-fake', `${scrollY}`)
			body.style.position = 'fixed'
			scrollY += 'px'
			body.style.top = `-${scrollY}`
		} else {
			body.classList.remove('arc-gallery-opened')
			const scrollY = body.style.top
			body.style.position = ''
			body.style.top = ''
			window.scrollTo(0, parseInt(scrollY || '0') * -1)
			document.documentElement.style.removeProperty('--scroll-y-fake')
		}
	},
	{ immediate: true }
)
watch(
	() => trialEnded.value,
	(isMustBuy) => {
		if (isMustBuy === true && isUserLogged === false) {
			setTimeout(() => {
				store.commit('interfaceSettings/pricingWindowShowed', true)
			}, 2000)

		}
	},
	{ immediate: true }
)
onBeforeMount(() => {
	store.commit('interfaceSettings/initialiseStore')
})
onMounted(() => {

	if (isCookieAlertShowedStore.value === false) {
		setTimeout(() => {
			showCookiesAlert.value = true
		}, 1000)
	}
})

watch(
	() => isCookieAlertShowedStore.value,
	(isCookieShowed) => {
		if (isCookieShowed === false) {

			showCookiesAlert.value = true
		}
	},
	{ immediate: false, deep: true }
)
</script>

<template>
	<component :is="layout">
		<router-view />
	</component>
	<div class="loading-indicator-box" :class="{ showed: isLoadedAdditionColumns === false }">
		<span>{{ loadingText }} . . .</span>
		<i><img src="@/assets/images/vector/loading-columns.svg" alt="" /></i>
	</div>
	<Transition name="fade-up">
		<fileupload-box v-if="searchByFigureModalOpen === true" />
	</Transition>
	<Transition name="scale-up">
		<login-window v-if="isLoginModalOpen === true" />
	</Transition>
	<Transition name="cookies">
		<cookieAlert v-if="showCookiesAlert === true" @close="showCookiesAlert = false" />
	</Transition>
	<Transition name="fade">
		<pricing-window
			v-if="pricingWindowShowed === true"
			@close="store.commit('interfaceSettings/pricingWindowShowed', false)"
		/>
	</Transition>
	<Transition name="fade">
		<contact-window
			v-if="contactWindowShowed === true"
			@close="store.commit('interfaceSettings/contactWindowShowed', false)"
		/>
	</Transition>
	<Transition name="fade">
		<guide-window
			v-if="isGuideModalOpen === true"
			@close="store.commit('interfaceSettings/guideModalOpen', false)"
		/>
	</Transition>
</template>
